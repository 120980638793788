import React from 'react'

import logo from '../../assets/images/bonotel_logo.svg'

const BonotelLogo = () => {
  return (
    <img className="bonotel-logo" src={logo} alt="Bonotel Logo"/>
  )
}

export default BonotelLogo