import './dataExport.css'

function DataExport() {
    return (
        <div className={"container"}>
            <iframe title={"dataExportFrame"} src="https://data.bonotel.com/dataExports" className={"iframe"}/>
        </div>
    )
}

export default DataExport