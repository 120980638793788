import './App.css';
import React from "react";
import Page from "./components/Page/Page";
import Specs from "./pages/specs/specs";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import BonotelLogo from "./components/BonotelLogo/BonotelLogo";
import { Button, List, ListItem } from "@mui/material";
import DataExport from "./pages/dataExport/dataExport";

function App() {
    const routes = [
        {
            path: "/data-export",
            exact: true,
            component: () => <Page><DataExport /></Page>
        },
        {
            path: "/specifications#system-updates",
            component: () => <Page><Specs /></Page>
        },
        {
            path: "/specifications#guides",
            component: () => <Page><Specs /></Page>
        },
        {
            path: "/specifications#main-specifications",
            component: () => <Page><Specs /></Page>
        },
        {
            path: "/specifications",
            exact: true,
            component: () => <Page><Specs /></Page>
        },
        {
            path: "/",
            component: () => <Page><Specs /></Page>
        },
    ]
    return (
        <Router>
            <div className={"App"}>
                <div className={"sidebar"}>
                    <List>
                        <section className={"logo"}>
                            <BonotelLogo />
                        </section>
                        <ListItem className={"item"}>
                            <Link to={"/specifications"} >
                                <Button className={"button"}>
                                    Specifications
                                </Button>
                            </Link>
                        </ListItem>
                        <ListItem className={"item"}>
                            <Link to={"/specifications#main-specifications"} >
                                <Button className={"button buttonSubItemText"}>
                                    Main Specifications
                                </Button>
                            </Link>
                        </ListItem>
                        <ListItem className={"item"}>
                            <Link to={"/specifications#guides"} >
                                <Button className={"button buttonSubItemText"}>
                                    Guides
                                </Button>
                            </Link>
                        </ListItem>
                        <ListItem className={"item"}>
                            <Link to={"/specifications#system-updates"} >
                                <Button className={"button buttonSubItemText"}>
                                    System Updates
                                </Button>
                            </Link>
                        </ListItem>
                        <ListItem className={"item"}>
                            <Link to={"/data-export"} >
                                <Button className={"button"}>
                                    Data Export
                                </Button>
                            </Link>
                        </ListItem>
                    </List>
                </div>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            exact={route.exact}
                            path={route.path}
                            children={<route.component />}
                        />
                    ))}
                </Switch>
            </div>
        </Router>

    )
}

export default App;
