import './Page.css'

function Page(props) {

    return (
        <section className={"page"}>
            <span className={"title"}>{props.title} </span>
            {props.children}
        </section>
    )
}

export default Page