import React, {useMemo, useRef} from 'react'
import './specs.css'
import {
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useLocation} from "react-router-dom";
import DownloadButton from "../../components/DownloadButton/DownloadButton";

function Specs() {
    let mainSpecRef = useRef(null);
    let guidesRef = useRef(null);
    let systemUpdatesRef = useRef(null);
    let location = useLocation()

    const headers = [
        {
            key: 0,
            name: 'DOC',
            width: 10,
        },
        {
            key: 1,
            name: 'Document Name',
            width: 300,
        },
        {
            key: 2,
            name: 'Description',
            width: 300,
        },
        {
            key: 3,
            name: 'Version',
            width: 10,
        },
        {
            key: 4,
            name: 'Date',
            width: 80,
        }]
    const tables = useMemo(() => [
        {
            key: 0,
            name: "Main Specifications",
            ref: mainSpecRef,
            rows: [
                {
                    key: 0,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_API_Specs_V1.23.docx"}/>,
                    documentName: "XML Spec: Hotel Availability/Reservation/Cancellation/Lookup",
                    description: "Specifications for our XML interface.",
                    version: "1.23",
                    date: "MAY 2022"
                },
                {
                    key: 1,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_API_PreBookMethod_V1.1.docx"}/>,
                    documentName: "XML Spec: PreBook Method",
                    description: "Specifications for the PreBook method, to be used before the Reservation method.",
                    version: "1.1",
                    date: "JAN 2023"
                },
                {
                    key: 2,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_API_LookupREservationMethod_V2.docx"}/>,
                    documentName: "XML Spec: Lookup Method V2",
                    description: "Specifications for the new version of Reservaiton Lookup.",
                    version: "1",
                    date: "JUN 2018"
                },
                {
                    key: 3,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_API_ReservationReportMethod.docx"}/>,
                    documentName: "XML Spec: Reservation Report",
                    description: "Specifications for our new Reservaiton Report Method.",
                    version: "1",
                    date: "JUN 2018"
                },
                {
                    key: 4,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_DataAPI_V1.5.doc"}/>,
                    documentName: "XML Spec: Data API",
                    description: "Additional data, including extended descriptions and additional photos.",
                    version: "1.5",
                    date: "AUG 2014"
                },
                {
                    key: 5,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_HotelContentAndData_V1.3.doc"}/>,
                    documentName: "Bonotel Hotel Content & Data",
                    description: "Details covering our static data exports, data API, and photo options.",
                    version: "1.3",
                    date: "DEC 2019"
                },
            ]
        },
        {
            key: 1,
            name: "Guides",
            ref: guidesRef,
            rows: [
                {
                    key: 0,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_API_CancellationPolicyDetails_V3.doc"}/>,
                    documentName: "Cancellation and Modify Policy Details",
                    description: "Details regarding our cancellation and modification policy format.",
                    version: "3",
                    date: "OCT 2016"
                },
                {
                    key: 1,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_API_OptimizationGuide_V2.doc"}/>,
                    documentName: "Bonotel Optimization Guide",
                    description: "Critical features, suggestions and ideas to optimize or improve your connection with us.",
                    version: "2",
                    date: "OCT 2016"
                },
            ]
        },
        {
            key: 2,
            name: "System Updates",
            ref: systemUpdatesRef,
            rows: [
                {
                    key: 0,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_StaticDataUpdates_2017.doc"}/>,
                    documentName: "Static Data Updates",
                    description: "New HotelData file and older files retiring on 2/1/2017.",
                    version: "1",
                    date: "OCT 2016"
                },
                {
                    key: 1,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_XML_HotelFeesUpdate_V1.doc"}/>,
                    documentName: "DEPLOYMENT: Hotel Fees Update - Live Server",
                    description: "Optional update to go live 01-Aug-2015.",
                    version: "1",
                    date: "SEPT 2015"
                },
                {
                    key: 2,
                    doc: <DownloadButton path={"/specs/"} filename={"Bonotel_Deployment_3XML_Updates_V1.doc"}/>,
                    documentName: "DEPLOYMENT: Three Mandatory Updates - Live Server",
                    description: "Required updates live 11-Nov-2014.",
                    version: "1.2",
                    date: "SEPT 2014"
                },
            ]
        }
    ],[mainSpecRef, guidesRef, systemUpdatesRef]);

    React.useEffect(() => {
        if (location.hash === '#main-specifications') {
            tables[0].ref.current.scrollIntoView()
        } else if (location.hash === '#guides') {
            tables[1].ref.current.scrollIntoView()
        } else if (location.hash === '#system-updates') {
            tables[2].ref.current.scrollIntoView()
        }
    }, [location, tables]);

    return (
        <div>
            <div>
                <p><b>NOTE: If you connected to Bonotel's API before 1-JAN-2023, you are using the API covered on this site.
                        We do not have plans to sunset this API. For all new connections, please see our new API via Above
                        Property:</b>
                </p>
                <ul>
                    The Above Property API for Bonotel Specifications: <a
                        href="https://developer.aboveproperty.com/">https://developer.aboveproperty.com/</a>
                    <li>The sign up form is located here: <a
                            href="https://developer.aboveproperty.com/auth/email">https://developer.aboveproperty.com/auth/email</a>
                    </li>
                </ul>
                <ul>
                    API Support for this API is provided here: <a
                        href="https://aboveproperty.zendesk.com/">https://aboveproperty.zendesk.com/</a>
                    <li>Once the site loads, click on the Sign in button located in the top right corner.</li>
                    <li>On the page that loads, below the login fields, you’ll see "New to Above Property? Sign Up" - click this
                        link to register for the support site.</li>
                    <p></p>
                </ul>
            </div>
            <div className={"specs"}>
                {tables.map(table => {
                    return (
                        <div key={table.key}>
                            <Typography variant={"h5"} className={"specs-title"} ref={table.ref}>
                                {table.name}
                            </Typography>
                            <Paper variant="outlined" style={{marginBottom: 15}}>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {headers.map(header => {
                                                    return (
                                                        <TableCell style={{fontWeight: "bold", width: header.width}} key={header.key}>
                                                            {header.name}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {table.rows.map(row => {
                                                return (
                                                    <TableRow key={row.key}>
                                                        <TableCell>
                                                            {row.doc}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.documentName}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.version}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.date}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })

                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}

export default Specs;