import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import "./DownloadButton.css"

function DownloadButton(props) {
    return (
        <a href={props.path + props.filename} download={props.filename} className={"download-button"}><DownloadIcon/></a>
    )
}

export default DownloadButton